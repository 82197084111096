<template>
  <LacettiFuses msg="Welcome to Your Vue.js App"/>
</template>

<script>
import LacettiFuses from './components/LacettiFuses.vue'

export default {
  name: 'App',
  components: {
    LacettiFuses
  }
}
</script>
