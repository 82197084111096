<template>
  <div class="lf">
    <div class="container">
      <h1>Предохранители Chevrolet Lacetti</h1>
      <div class="mb-3 mt-3">
        <input
            type="text"
            class="form-control"
            id="search"
            placeholder="Что не работает или имя предохранителя"
            aria-describedby="search"
            v-model="searchString"
        >
      </div>
      <div class="mb-3">
        <details>
          <summary>Блок предохранителей в салоне</summary>
          <img src="@/assets/fuses-cabin.webp" alt="fuses">
        </details>
        <details class="mt-3">
          <summary>Блок предохранителей под капотом</summary>
          <img src="@/assets/fuses-hood.webp" alt="fuses">
        </details>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th class="lf__column-title">Имя</th>
            <th class="lf__column-title">Номинал (А)</th>
            <th class="lf__column-title">Расположение</th>
            <th class="lf__column-title">Описание</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{name, description, nominalValue, location} in filteredFuses" :key="name">
            <td>{{ name }}</td>
            <td>{{ nominalValue }}</td>
            <td>{{ location === 'cabin' ? 'в салоне' : 'под капотом' }}</td>
            <td>{{ description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { FUSES } from './data'

export default {
  name: 'LacettiFuses',
  created () {
    this.FUSES = FUSES
  },
  data () {
    return {
      searchString: ''
    }
  },
  computed: {
    filteredFuses () {
      const str = this.searchString.trim()

      if (!str) {
        return [...this.FUSES]
      }

      return this.FUSES.filter(({name, description}) => {
        if (name.includes(str) || description.includes(str)) {
          return true
        }

        return false
      })
    }
  }
}
</script>

<style lang="scss">
.lf__column-title {
  white-space: nowrap;
}
</style>
