export const FUSES = [
  {
    name: 'F1',
    description: 'подушки безопасности',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F2',
    description: 'электронный блок управления двигателем (ЭБУ), блок управления АКПП, датчик положения селектора АКПП, датчик скорости, генератор, э/м клапан впускного коллектора, обмотка реле бензонасоса, катушек зажигания, обмотка главного реле',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F3',
    description: 'аварийный выключатель',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F4',
    description: 'приборная панель, выключатель стоп-сигналов, автоматическая работа кондиционера, электропривод заслонки, гидроусилитель руля, сигнал предупреждения',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F5',
    description: 'не используется',
    nominalValue: 0,
    location: 'cabin'
  },
  {
    name: 'F6',
    description: 'реле компрессора кондиционера, фар, обогрева заднего стекла, электростеклоподъёмников',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F7',
    description: 'отопитель, электропривод заслонок рециркуляции и направления воздуха, высокая скорость работы вентилятора печки, автоматическое управление кондиционером',
    nominalValue: 20,
    location: 'cabin'
  },
  {
    name: 'F8',
    description: 'электропривод зеркал, складывание зеркал, управление люком (при наличии)',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F9',
    description: 'электропривод зеркал, складывание зеркал, управление люком (при наличии)',
    nominalValue: 25,
    location: 'cabin'
  },
  {
    name: 'F10',
    description: 'не используется',
    nominalValue: 0,
    location: 'cabin'
  },
  {
    name: 'F11',
    description: 'ABS',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F12',
    description: 'иммобилайзер, датчик дождя, сигнализация',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F13',
    description: 'управление АКПП',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F14',
    description: 'аварийная сигнализация',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F15',
    description: 'блок управления сигнализации',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F16',
    description: 'диагностический разъём',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F17',
    description: 'магнитола, часы',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F18',
    description: 'дополнительная розетка 12 В',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F19',
    description: 'прикуриватель',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F20',
    description: 'свет заднего хода, датчик положения селектора АКПП, свет в салоне',
    nominalValue: 10,
    location: 'cabin'
  },
  {
    name: 'F21',
    description: 'задние противотуманные фонари',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F22',
    description: 'часы, отопление и автоматическая работа кондиционера',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F23',
    description: 'аудиосистема (магнитола). См. F17',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'F24',
    description: 'иммобилайзер. См. F12',
    nominalValue: 15,
    location: 'cabin'
  },
  {
    name: 'Ef1',
    description: 'Основной аккумулятор (цепи F13-F16, F21-F24)',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef2',
    description: 'ABS. См. F11',
    nominalValue: 60,
    location: 'hood'
  },
  {
    name: 'Ef3',
    description: 'вентилятор печки. См. F7',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef4',
    description: 'зажигание (стартер, цепи F5-F8)',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef5',
    description: 'зажигание (цепи F1-F4, F9-F12, F17-F19). Проверьте реле К3',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef6',
    description: 'вентилятор системы охлаждения (радиатора)',
    nominalValue: 20,
    location: 'hood'
  },
  {
    name: 'Ef7',
    description: 'обогрев заднего стекла. См. F6',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef8',
    description: 'большая скорость работы вентилятора системы охлаждения (радиатора). См. Ef6',
    nominalValue: 30,
    location: 'hood'
  },
  {
    name: 'Ef9',
    description: 'электрические стеклоподъёмники в правой передней и в задних дверях. См. F6',
    nominalValue: 20,
    location: 'hood'
  },
  {
    name: 'Ef10',
    description: 'электронный блок управления (ЭБУ), катушки зажигания, клапан рециркуляции отработавших газов',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef11',
    description: 'цепь главного реле, контроллер электронной системы управления двигателем (ЭСУД)',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef12',
    description: 'фары, габариты',
    nominalValue: 25,
    location: 'hood'
  },
  {
    name: 'Ef13',
    description: 'стоп-сигналы',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef14',
    description: 'электрический стеклоподъёмник в водительской двери. См. F6',
    nominalValue: 20,
    location: 'hood'
  },
  {
    name: 'Ef15',
    description: 'лампы дальнего света в фарах',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef16',
    description: 'звуковой сигнал, сирена, концевой выключатель капота',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef17',
    description: 'компрессор кондиционера. См. F6',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef18',
    description: 'топливный насос',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef19',
    description: 'приборная панель, электропривод складывания зеркал, лампы индивидуального света в салоне, общий плафон света в салоне, свет в багажнике, концевой выключатель положения багажника. См. F4',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef20',
    description: 'левая фара, ближний свет',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef21',
    description: 'электронный блок управления (ЭБУ), клапан продувки адсорбера, датчики концентрации кислорода, датчик фаз, вентилятор системы охлаждения (радиатора)',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef22',
    description: 'бензонасос, форсунки, клапан рециркуляции отработавших газов',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef23',
    description: 'лампы габаритного света с левой стороны, лампа освещения госномера, сигнал предупреждения. См. Ef12',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef24',
    description: 'противотуманные фары',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef25',
    description: 'электропривод боковых зеркал. См. F8',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef26',
    description: 'центральный замок',
    nominalValue: 15,
    location: 'hood'
  },
  {
    name: 'Ef27',
    description: 'правая фара, ближний свет. См. Ef2',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef28',
    description: 'лампы габаритного света с правой стороны, подсветка приборной панели и центральной консоли, подсветка магнитолы, часы',
    nominalValue: 10,
    location: 'hood'
  },
  {
    name: 'Ef29',
    description: 'резервный',
    nominalValue: 0,
    location: 'hood'
  },
  {
    name: 'Ef30',
    description: 'резервный',
    nominalValue: 0,
    location: 'hood'
  },
  {
    name: 'Ef31',
    description: 'резервный',
    nominalValue: 0,
    location: 'hood'
  },
]
